
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { dark, syntaxHighlighterPrism } from "@mdx-deck/themes";
import { Box } from "theme-ui";
export const theme = { ...syntaxHighlighterPrism,
  ...dark
};
export const Highlighted = ({
  children
}) => <Box color="tomato" style={{
  display: "inline-block"
}} mdxType="Box">
    {children}
  </Box>;
export const Link = ({
  children,
  href
}) => <a href={href} style={{
  color: "white"
}}>
    {children}
  </a>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Steps = makeShortcode("Steps");
const layoutProps = {
  theme,
Highlighted,
Link
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`NFT: Behind The Scene`}</h1>
    <p>{`Right click and upload`}</p>
    <hr></hr>
    <h2>{`\\$ whoami`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Raymond Yeh`}</li>
        <li parentName="ul">{`CTO @ `}<Link href="https://bluejay.finance/" mdxType="Link">{`Bluejay.Finance`}</Link></li>
        <li parentName="ul">{`Creator @ `}<Link href="https://floppydisknft.com/" mdxType="Link">{`Floppy Disk NFT`}</Link></li>
        <li parentName="ul">{`MEV Bot Operator (`}<Link href="https://t.me/hypednftmint" mdxType="Link">{`Current Experiment`}</Link>{`)`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`What's up`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Smart Contracts Explained`}</li>
        <li parentName="ul">{`What is NFT (ERC721 vs ERC1155)`}</li>
        <li parentName="ul">{`Behind the scene of an NFT`}</li>
        <li parentName="ul">{`Generative art`}</li>
        <li parentName="ul">{`Minting your NFT`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Ethereum is a BIG computer`}</h2>
    <small>Albeit a slow one...</small>
    <hr></hr>
    <h2>{`A computer can`}</h2>
    <ul>
      <li parentName="ul">{`Store data`}</li>
      <li parentName="ul">{`Run different programs`}</li>
    </ul>
    <hr></hr>
    <h2>{`Ethereum can`}</h2>
    <ul>
      <li parentName="ul">{`Store states`}</li>
      <li parentName="ul">{`Run smart contract that defines state transition (or rules)`}</li>
    </ul>
    <hr></hr>
    <h2>{`A computer running Excel`}</h2>
    <img src="/assets/excel.png" style={{
      height: "45%"
    }} />
    <hr></hr>
    <h2>{`Ethereum running an ERC721 Smart Contract`}</h2>
    <img src="/assets/excel-erc721.png" style={{
      height: "45%"
    }} />
    <small>Wait what about who can edit?</small>
    <hr></hr>
    <h2>{`Sample rules`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Only when 0.01 ETH is sent, a new entry will be created with owner set to msg.sender (Minting)`}</li>
        <li parentName="ul">{`Maximum of 10,000 entry is allowed (Max no. tokens)`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`tokenUri`}</inlineCode>{` is `}<inlineCode parentName="li">{`baseUri`}</inlineCode>{` + `}<inlineCode parentName="li">{`tokenId`}</inlineCode>{` (Token metadata)`}</li>
        <li parentName="ul">{`Only current owner of `}<inlineCode parentName="li">{`tokenId`}</inlineCode>{` or approved addresses can update the owner field (Transfer)`}</li>
        <li parentName="ul">{`etc`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`What if every program is written differently?`}</h2>
    <p><inlineCode parentName="p">{`ownerOf`}</inlineCode>{`? `}<inlineCode parentName="p">{`ownedBy`}</inlineCode>{`? `}<inlineCode parentName="p">{`owned`}</inlineCode>{`?`}</p>
    <hr></hr>
    <h2>{`The rise of standards`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">
          <Link href="https://eips.ethereum.org/EIPS/eip-20" mdxType="Link">ERC20</Link> - Token standards
        </li>
        <li parentName="ul">
          <Link href="https://erc721.org/" mdxType="Link">ERC721</Link> - Non-fungible token standards
        </li>
        <li parentName="ul">
          <Link href="https://docs.opensea.io/docs/metadata-standards" mdxType="Link">
  Opensea Metadata
          </Link> - NFT Metadata standard
        </li>
        <li parentName="ul">
          <Link href="https://eips.ethereum.org/EIPS/eip-1155" mdxType="Link">ERC1155</Link> - Multi Token
Standard
        </li>
      </ul>
    </Steps>
    <small>Fun Fact: Crypto Punks were pre-ERC721</small>
    <hr></hr>
    <h2>{`ERC721`}</h2>
    <pre><code parentName="pre" {...{}}>{`// Some examples
function ownerOf(uint256 _tokenId) external view returns (address);
function balanceOf(address _owner) external view returns (uint256);
function transferFrom(address _from, address _to, uint256 _tokenId) external payable;
function approve(address _approved, uint256 _tokenId) external payable;
function setApprovalForAll(address _operator, bool _approved) external;
`}</code></pre>
    <hr></hr>
    <h2>{`ERC721 vs ERC1155?`}</h2>
    <hr></hr>
    <h2>{`ERC721`}</h2>
    <img src="/assets/excel-erc721.png" style={{
      height: "45%"
    }} />
    <hr></hr>
    <h2>{`ERC1155`}</h2>
    <img src="/assets/excel-erc1155.png" style={{
      height: "45%"
    }} />
    <hr></hr>
    <h2>{`Let's Play!`}</h2>
    <ul>
      <li parentName="ul">
        <Link href="https://opensea.io/assets/0x60e4d786628fea6478f785a6d7e704777c86a7c6/1" mdxType="Link">
  Mutant Ape Contract
        </Link>
      </li>
      <li parentName="ul">
        <Link href="https://opensea.io/assets/0x892848074ddea461a15f337250da3ce55580ca85/1" mdxType="Link">
  Cyber Broker Contract
        </Link>
      </li>
      <li parentName="ul">
        <Link href="https://opensea.io/assets/0x59468516a8259058bad1ca5f8f4bff190d30e066/1" mdxType="Link">
  Invisible Friends Contract
        </Link>
      </li>
      <li parentName="ul">
        <Link href="https://opensea.io/assets/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb/1" mdxType="Link">
  Crypto Punks Contract
        </Link>
      </li>
      <li parentName="ul">
        <Link href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/91015854458775626546266917337677518118210678757598385512114170878752332972042" mdxType="Link">
  OpenSea Shared Storefront
        </Link>
      </li>
      <li parentName="ul">
        <Link href="https://opensea.io/assets/0xBcA5D4f9C1B6D5C8E0BFfc8a5937948112CE3F19/290" mdxType="Link">
  Super Cool Peeps
        </Link>
      </li>
      <li parentName="ul">
        <Link href="https://opensea.io/assets/0x06536bd54dd9b11ff664dc2880595aa4976188c3/45" mdxType="Link">
  Floppy Disk Contract
        </Link>
      </li>
    </ul>
    <small>
  Read Code | Query | Write from Etherscan | Sending Raw Transaction
    </small>
    <hr></hr>
    <h2>{`What's in `}<inlineCode parentName="h2">{`tokenURI`}</inlineCode></h2>
    <pre><code parentName="pre" {...{}}>{`// Mutant Ape Yatch Club
https://boredapeyachtclub.com/api/mutants/9260

// Invisible Friends
https://invisiblefriends.io/api/10

// Cyber Broker
ipfs://QmYjhUCiQrYZCZNRk2wNbfuNgJF6Aan1dmnydPYjyS6DQ1/10

// Super Cool Peeps
data:application/json;base64eyJuYW1lIjoiUEVFUC...cG5nIn0=

// OpenSea Shared Storefront
https://api.opensea.io/api/v1/metadata/0x495f947276749Ce646f68AC8c248420045cb7b5e/0xc93925e7af849be65c61fcccadf28c1d8fce0f5c00000000000031000000000a
`}</code></pre>
    <hr></hr>
    <h2>{`Metadata - Everything about the NFT`}</h2>
    <img src="/assets/metadata-standard.png" style={{
      height: "45%"
    }} />
    <Link href="https://docs.opensea.io/docs/metadata-standards" mdxType="Link">
  OpenSea Metadata Standard
    </Link>
    <hr></hr>
    <h2>{`Ways to host the Metadata`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Centralized Server (MAYC/Invisible Friends/OpenSea Shared Storefront)`}</li>
        <li parentName="ul">{`IPFS (Cyber Broker/Floppy Disk)`}</li>
        <li parentName="ul">{`Smart Contract (Super Cool Peeps/Uniswap V3/Timeswap)`}</li>
      </ul>
    </Steps>
    <small>
  Eg.{" "}
  <Link href="https://opensea.io/assets/0xc36442b4a4522e871399cd717abdd847ab11fe88/69821" mdxType="Link">
    Uniswap V3
  </Link>{" "}
  |{" "}
  <Link href="https://testnets.opensea.io/assets/0x5216b15f14914023c2092b8486f694f8fe2766f6/325" mdxType="Link">
    Timeswap
  </Link>
  | <Link href="https://api.opensea.io/api/v1/metadata/0x495f947276749Ce646f68AC8c248420045cb7b5e/0xc93925e7af849be65c61fcccadf28c1d8fce0f5c00000000000031000000000a" mdxType="Link">OpenSea Shared Storefront</Link>
    </small>
    <hr></hr>
    <h2>{`Hosting Metadata`}</h2>
    <h3>{`Centralized Server vs IPFS vs Smart Contract`}</h3>
    <small>Remember you may be able change your baseURI anyway...</small>
    <hr></hr>
    <h2>{`How???`}</h2>
    <Steps mdxType="Steps">
      <ul>
  <li> OpenSea Shared Storefront </li>
  <li>
    NFT Launchpads (<Link href="https://www.artlab.xyz/" mdxType="Link">Artlab</Link>
    {", "}
    <Link href="https://wenmint.com/" mdxType="Link">Wenmint</Link>
    {", "}
    <Link href="https://www.launchmynft.io/" mdxType="Link">Launchmynft</Link>
    {", "}
    <Link href="https://www.airnfts.com/" mdxType="Link">Airnfts</Link>)
  </li>
  <li>
    {" "}
    Generate and store on IPFS (<Link href="https://www.pinata.cloud/" mdxType="Link">
      Pinata
    </Link>)
  </li>
  <li> On-demand generation with hosted services (AWS Lambda)</li>
  <li>
    Custom smart contract metadata (
    <Link href="https://github.com/Uniswap/v3-periphery/blob/main/contracts/libraries/NFTDescriptor.sol" mdxType="Link">
      Descriptor
    </Link>
    , <Link href="https://github.com/Uniswap/v3-periphery/blob/main/contracts/libraries/NFTSVG.sol" mdxType="Link">
      SVG
    </Link>)
  </li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Generated NFTs`}</h2>
    <img src="/assets/squirrels.gif" style={{
      height: "45%"
    }} />
    <small>...or you can hand-create 10k images</small>
    <hr></hr>
    <h2>{`NFT generated from layers`}</h2>
    <img src="/assets/layers.png" style={{
      height: "45%"
    }} />
    <small>Easily 1 mil unique images from &lt; 60 traits</small>
    <hr></hr>
    <h2>{`How???`}</h2>
    <Steps mdxType="Steps">
      <ul>
  <li>
    Online NFT Generator (<Link href="https://nft-inator.com/" mdxType="Link">NFT-Inator</Link>
    {", "}
    <Link href="https://www.niftygenerator.xyz/" mdxType="Link">nifty generator</Link>)
  </li>
  <li>
    Custom Scripts (
    <Link href="https://github.com/rounakbanik/generative-art-nft" mdxType="Link">[1]</Link>
    {", "}
    <Link href="https://github.com/codeSTACKr/create-10k-nft-collection" mdxType="Link">
      [2]
    </Link>
    )
  </li>
      </ul>
    </Steps>
    <small>
  <Link href="https://nft-inator.com/app" mdxType="Link">Let's play</Link>
    </small>
    <hr></hr>
    <h2>{`Recap`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`What are smart contracts?`}</li>
        <li parentName="ul">{`What are NFTs?`}</li>
        <li parentName="ul">{`What is the difference between ERC721 `}{`&`}{` ERC1155?`}</li>
        <li parentName="ul">{`What are metadata?`}</li>
        <li parentName="ul">{`How are metadata & images stored?`}</li>
        <li parentName="ul">{`How are generative NFT made?`}</li>
        <li parentName="ul">{`What platforms are available?`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`The NFT Creator Stack`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Art`}</li>
        <li parentName="ul">{`Marketing`}</li>
        <li parentName="ul">{`Community Management`}</li>
        <li parentName="ul">{`Development`}</li>
        <li parentName="ul">{`Distribution`}</li>
        <li parentName="ul">{`Intellectual Property`}</li>
        <li parentName="ul">{`Governance`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Walkthrough of Creating OS Marketplace`}</h2>
    <small>
  <Link href="how-to-create-your-own-marketplace-on-opensea-in-three-minutes-or-less-12373ca5818a
" mdxType="Link">
    Step-by-step-guide
  </Link>
    </small>
    <hr></hr>
    <h1>{`Q`}{`&`}{`A`}</h1>
    <hr></hr>
    <h2>{`Resources`}</h2>
    <pre><code parentName="pre" {...{}}>{`Basic:
https://www.youtube.com/watch?v=ftMzXpToq9Q
https://medium.com/opensea/how-to-create-your-own-marketplace-on-opensea-in-three-minutes-or-less-12373ca5818a

Advanced:

https://levelup.gitconnected.com/how-to-mint-100-000-nfts-for-free-62d83888ff6
https://github.com/alephao/nft-sale-proxy
https://docs.nftport.xyz/docs/nftport/ZG9jOjI4NzY0NTY1-how-to-create-dynamic-nf-ts

Expert:

https://github.com/Uniswap/v3-periphery/blob/main/contracts/libraries/NFTDescriptor.sol
https://github.com/Uniswap/v3-periphery/blob/main/contracts/libraries/NFTSVG.sol
`}</code></pre>
    <hr></hr>
    <h1>{`END`}</h1>
    <ul>
      <li parentName="ul">
        <Link href="https://twitter.com/geek_sg" mdxType="Link">Twitter</Link>
      </li>
      <li parentName="ul">
        <Link href="https://www.linkedin.com/in/raymondyeh/" mdxType="Link">LinkedIn</Link>
      </li>
      <li parentName="ul">
        <Link href="https://geek.sg/" mdxType="Link">Blog</Link>
      </li>
      <li parentName="ul">
        <Link href="https://bluejay.finance/" mdxType="Link">Bluejay Finance</Link>
      </li>
      <li parentName="ul">
        <Link href="https://floppydisknft.com/" mdxType="Link">Floppy Disk NFT</Link>
      </li>
    </ul>
    <hr></hr>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;